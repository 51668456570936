import styles from './FeaturedIn.module.css';
import Image from 'next/image';
export function FeaturedIn() {
    return <section className={styles.feautredIn}>
        <div className="container">
            <h2 className="sectionHeading mb15">Featured In</h2>
            <ul className={styles.allnews}>
                <li><Image className={styles.feautredInimg} src="/assets/images/brand-1.png" alt="" height={35} width={160}/></li>
                <li><Image className={styles.feautredInimg} src="/assets/images/brand-2.png" alt="" height={35} width={160}/></li>
                <li><Image className={styles.feautredInimg} src="/assets/images/brand-3.png" alt="" height={35} width={160}/></li>
                <li><Image className={styles.feautredInimg} src="/assets/images/brand-4.png" alt="" height={35} width={160}/></li>
                <li><Image className={styles.feautredInimg} src="/assets/images/brand-5.png" alt="" height={35} width={160}/></li>
            </ul>
        </div>
    </section>
}