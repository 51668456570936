import styles from './LabtestBanner.module.css';
import Image from 'next/image';
export function LabtestBanner() {
    return <section className="labtestBanner">
        <div className="container">
            <div className={styles.labBanner}>
                <Image className={`${styles.labBannerImg} imgResponsive`} src="/assets/images/labimgBanner.png" alt="" height={525} width={963}/>
                <div className={`${styles.Labcontent} textcenter`}>
                    <p className="font40 fontsm18 textfff  fontBold  textcap">lab test <br />at your convenience</p>
                    <p className="font35 fontsm16 fontThin textfff mb10">270+ cities around across India</p>
                    <p className="font20 fontsm12 textfff fontThin  mb30">Book a lab test & get your sample</p>
                    <button className={`btn ${styles.btnsecondry} btn-secondry font20 fontsm14 font600 textcap`}>Book a lab test </button>
                </div>
            </div>
        </div>
    </section>
}