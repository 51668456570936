import {  IHowItWorks } from '@/interfaces';
import styles from './HowItWorks.module.css';
import Image from 'next/image';
type props = {
    howItWorksData: IHowItWorks[],
    isAssessment?:boolean
}
export function HowItWorks({ howItWorksData,isAssessment }: props) {
    return <section className={`${styles.howItWorks} ${isAssessment && 'pt50 pb50 backgroundf5f5f5'}`}>
        <div className="container">
            <h2 className={`sectionHeading ${styles.sectionHeading}`}>How it Works</h2>
            <div className={styles.howItworksWrap}>
                {howItWorksData && howItWorksData.map((f, idx) =>
                    <div className={styles.steps} key={idx}>
                        <div className="stepsHead">
                            <p className="textcenter nowrap font18 fontsm14 fontThin mb12">{f.title}</p>
                            <figure className={`${styles.stepsIcon} textcenter mb15`}>
                                <Image className="imgResponsive" src={f.icon} alt={f.title} height={60} width={60} />
                            </figure>
                        </div>
                        <div className={styles.stepsDetails}>
                            <p className="font20 fontsm16 mb10 font600 text506E61">
                                {f.heading}
                            </p>
                            <p className="font16 fontsm12 lineHeight24 normallineheight fontThin">{f.content}</p>
                        </div>
                    </div>)}
            </div>
        </div>
    </section>
}