import { ICategories } from '@/interfaces'
import styles from './WhatWeTreat.module.css'
import Image from 'next/image'
import { NavLink } from '@/components/shared/ui'
import { useGlobalContext } from '@/libs/context/GlobalContext'
type props = {
    categories: ICategories[]
}
export function WhatWeTreat({ categories }: props) {
    const { props: { siteUrl } } = useGlobalContext()
    return <section className={styles.whatWetreat}>
        <div className="container">
            <h2 className="sectionHeading">What We Treat</h2>
            <div className={styles.whatWeOffer}>
                <ul>
                    {categories && categories.sort((a: any, b: any) => a.sort_order - b.sort_order).map((f: ICategories, idx: number) =>
                        <li key={idx}>
                            <NavLink href={`${siteUrl}/${f.slug}`}>
                                <Image className={styles.offerImages} src={f.thumbnail} alt={f.name} height={210} width={235} />
                                <p className="fontThin textcenter  font20 fontsm14 lineHeight24 ">{f.name}</p>
                            </NavLink>
                        </li>
                    )}
                </ul>
            </div>
        </div>
    </section>
}