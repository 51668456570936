import Image from 'next/image'
import styles from './Banner.module.css'
import { useGlobalContext } from '@/libs/context/GlobalContext'
import { IHomeData } from '@/interfaces'
import { NavLink } from '@/components/shared/ui'
type props = {
    bannerData: IHomeData
}
export function BannerSection({ bannerData }: props) {
    const { props: { isMobileView, siteUrl } } = useGlobalContext()
    return <section className={styles.main_banner}>
        <div className="row">
            <div className="large-12 columns">
                <div>
                    <div className={`item  ${styles.bannerBg6}`}>
                        <div className="container">
                            <div className={`${styles.banner_area} ${styles.grid_banner}`}>
                                <div className={`${styles.banner_content}`}>
                                    <h4>Making Healthcare <br />Accessible and Affordable</h4>
                                    <div><p>Empowering over 2 million people with our high-quality products and treatments</p></div>
                                    <NavLink href={`${siteUrl}/self-assessment`}><button className={`btn btn-primary font14  textUpper ${styles.cta_btn}  ${styles.mobile_d_none}`}>Take a Self-Assessment</button></NavLink>
                                </div>
                                <div className={styles.img_top_padding} style={{ height: "100%" }}><Image className={`${styles.img1_ban} ${styles.fit}`} src="assets/images/slider-image1.png" alt="Banner1" height={isMobileView ? 308 : 500} width={isMobileView ? 345 : 570} /></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className={styles.dextop_d_none}>
            <div className={styles.ctn_bottom}><NavLink href={`${siteUrl}/self-assessment`} className={styles.mobile_cta_btn}>Take a Self-Assessment</NavLink></div>
        </div>
    </section>
}