import { IColors, IPatientStories } from '@/interfaces';
import styles from './PatientStories.module.css';
import Image from 'next/image';
import { NavLink } from '@/components/shared/ui';
import { useGlobalContext } from '@/libs/context/GlobalContext';
import { Scroller } from '@/components/scroller';
import { useRef } from 'react';
type props = {
    patientStories: IPatientStories[],
    colors?: IColors
}
export function PatientStories({ patientStories, colors }: props) {
    const { props: { siteUrl, isMobileView } } = useGlobalContext()
    const patientStoriesWrap = useRef(null);
    return <section className={styles.patientStories} style={{ background:'rgb(245, 245, 245)' }}>
        <div className="container">
            <h2 className="sectionHeading mb15">Patient Stories</h2>
            <div className={styles.patientRelative}>
                <div className={`${styles.patientStoriesWrap} hideScroller`} ref={patientStoriesWrap}>
                    {patientStories.map((f: IPatientStories, idx: number) =>
                        <div className={`${styles.indPatients} indPatients`} key={idx}>
                            <figure className={styles.patientImg}>
                                <Image src={f.image || '/assets/images/customer-1.png'} alt={f.name} height={266} width={400} />
                            </figure>
                            <div className={styles.patientstoryContent}>
                                <div className={styles.patientstory}>
                                    <Image src="/assets/images/left-quote.png" alt="left-quote" width={35} height={34}/>
                                    <p className="font16 fontThin text333 lineHeight20">{f.description}</p>
                                </div>
                                <p className="font14 text000"><span className="fontsemiBold font18">{f.name}</span>{` ${f.age} yrs`}</p>
                            </div>
                        </div>
                    )}
                </div>
                <Scroller scrollContainerRef={patientStoriesWrap} noOfTile={patientStories} tileClass={'indPatients'} tilesToView={3} extraWidth={20} />
            </div>
        </div>
    </section>
}