import { IColors, IDoctorsList } from '@/interfaces';
import styles from './OurMedicalBoard.module.css';
import Image from 'next/image';
import { NavLink } from '@/components/shared/ui';
import { useGlobalContext } from '@/libs/context/GlobalContext';
import { Scroller } from '@/components/scroller';
import { useRef } from 'react';
type props = {
    doctorsList: IDoctorsList[],
    colors?: IColors
}
export function OurMedicalBoard({ colors, doctorsList }: props) {
    const { props: { siteUrl } } = useGlobalContext()
    const scrollContainerRef = useRef(null);
    if(!doctorsList || doctorsList.length === 0) return <></>;
    return <section className={styles.ourmedicalBoard} style={{ background: colors?.light || 'rgb(245, 245, 245)' }}>
        <div className="container">
            <h2 className="sectionHeading mb15">Our Medical Board</h2>
            <div className="teamOnBoardWrap">
                <div className={styles.teamOnBoardMembers}>
                    <div className={`${styles.membersFlex} hideScroller`} ref={scrollContainerRef}>
                        {doctorsList && doctorsList.map((f: IDoctorsList, idx: number) =>
                            // <NavLink href={`${siteUrl}/doctors-profile/${f.slug}`}>
                            <div key={idx}>
                                <div className={`${styles.indMember} indMember`} >
                                    <figure className="mb20">
                                        <Image className="imgResponsive" src={f.thumbnail || '/assets/images/menvector.png'} alt={f.name} height={121} width={121} />
                                    </figure>
                                    <p className="font20 fontsm16 fontBold text506E61  nowrap">{f.name}</p>
                                    <p className={`mb5 font14 fontsm12 text506E61 lineclamp1`}>{f.role}</p>
                                    <p className={`font12 mb10 lineclamp1`}>{`(${f.speciality} - ${f.role})`}</p>
                                    <p className={`font14 fontsm12 fontThin lineHeight18 ${styles.bordertop} lineclamp3`}>{f.doctor_quote}</p>
                                    {/* <span className="text46675">Read More</span> */}
                                </div>
                            </div>
                            //  </NavLink>
                        )}
                    </div>
                    <Scroller scrollContainerRef={scrollContainerRef} noOfTile={doctorsList} tileClass={'indMember'} tilesToView={4} extraWidth={20} />
                </div>
            </div>
        </div>
    </section>
}