import { useEffect, useState } from 'react';
import styles from './OnNews.module.css'
import Image from 'next/image';
export function OnNews() {
    const array = [1, 2, 3, 4, 5];
    const [active, setActive] = useState(0);
    const [delay, setDelay] = useState(5000)
    useEffect(() => {
        const interval = setInterval(() => {
            setActive((prevIndex) =>
                prevIndex === array.length - 1 ? 0 : prevIndex + 1
            )
        }, delay);
        return () => clearInterval(interval);
    }, [active, array.length]);
    return <section className={styles.onNews}>
        <div className="container">
            <div className={styles.newsApearings}>
                <ul>
                    <li className={active === 0 ? '' : 'dnone'}>
                        <p className="font18 fontsm14 fontThin normallineheight mb20">“Startups disrupting the healthcare landscape through innovative solutions”</p>
                        <figure className={styles.logo}>
                            <Image className='imgResponsive' src='/assets/images/toilogo.png' height={52} width={133} alt='toilogo' />
                        </figure>
                    </li>
                    <li className={active === 1 ? '' : 'dnone'}>
                        <p className="font18 fontsm14 fontThin normallineheight mb20">“Digital Healthcare: Transforming India’s Healthcare Landscape, Beyond
                            Just Delivering Convenience”</p>
                        <figure className={styles.logo}>
                            <Image className='imgResponsive' src='/assets/images/theprintlogo.png' height={52} width={133} alt='theprintlogo' />
                        </figure>
                    </li>
                    <li className={active === 2 ? '' : 'dnone'}>
                        <p className="font18 fontsm14 fontThin normallineheight mb20">“Nirvasa&apos;s digital healthcare offerings: Searing through delivery barriers and social stigma”</p>
                        <figure className={styles.logo}>
                            <Image className='imgResponsive' src='/assets/images/ANI.png' height={52} width={133} alt='theprintlogo' />
                        </figure>
                    </li>
                    <li className={active === 3 ? '' : 'dnone'}>
                        <p className="font18 fontsm14 fontThin normallineheight mb20">“Nirvasa emerges as India&apos;s premier digital healthcare clinic”</p>
                        <figure className={styles.logo}>
                            <Image className='imgResponsive' src='/assets/images/BS.png' height={52} width={133} alt='theprintlogo' />
                        </figure>
                    </li>
                    <li className={active === 4 ? '' : 'dnone'}>
                        <p className="font18 fontsm14 fontThin normallineheight mb20">“NIRVASA Forays into Hair Care and Diabetes Care”</p>
                        <figure className={styles.logo}>
                            <Image className='imgResponsive' src='/assets/images/BizzBuzz.png' height={52} width={133} alt='theprintlogo' />
                        </figure>
                    </li>
                </ul>
                <div className="corouselNavs" id='howItWorks'>
                    {array && array.length > 0 && array.map((item, idx) => {
                        return <span key={idx} className={`dot ${active === idx ? 'active' : ''}`} onClick={() => { setActive(idx), setDelay(10000) }}></span>
                    })}
                </div>
            </div>
        </div>
    </section>
}