import { useState } from 'react';
import styles from './Faqs.module.css';
import Image from 'next/image';
import { IFaqs } from '@/interfaces';
type props = {
    faqs: IFaqs[]
}
export function Faqs({ faqs }: props) {
    const [active, setActive] = useState(-1)
    const handleClick = (e: any, index: number) => {
        e.preventDefault();
        setActive(prevState => (prevState === index ? -1 : index));
      };
    if (!faqs || faqs.length === 0) return null;
    return <section className={styles.faq}>
        <div className="container">
            <h2 className="sectionHeading mb15">FAQ</h2>
            <div className={styles.faqsWrap}>
                <div className={styles.faqimg}>
                    <Image className="imgResponsive" src="/assets/images/faq-removebg-preview.png" width={358} height={304} alt="" />
                </div>
                <div className={styles.allFaq}>
                    {faqs.map((f: IFaqs, idx: number) =>
                        <div className={`${styles.faqQuestion} ${active === idx ? styles.active : ''}`} onClick={(e) => handleClick(e, idx)} key={idx}>
                            <div className={`${styles.question} `}>
                                <p className="font16 fontsm14 text666 fontsemiBold">{f.question}</p>
                                <span className={`${`${styles.arw} arw`} arw`}></span>
                            </div>
                            <div className={`${styles.answer}`}>
                                <p className="font14 resplineheight18">{f.answer}</p>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    </section >
}