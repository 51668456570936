import styles from './NirvasaApproach.module.css';
import Image from 'next/image';
export function NirvasaApproach() {
    return <section className={`${styles.nirvasaApproach} textcenter`}>
        <div className="container">
            <div className={styles.foldsWrap}>
                <div className="approachcontent">
                    <h2 className="sectionHeading mb20">nirvasa 4-Fold Approach</h2>
                    <p className={`${styles.textalg} font18 fontsm14 fontThin mb10`}>We at Nirvasa follow a holistic approach to wellbeing that integrates 4 Wellness Pillars, helping to understand individual concerns and recommend personalised treatments.</p>
                    <ul className={styles.foldApproach_list}>
                        <li><strong> Prescribed by Doctors:</strong> We rely on medical care via certified doctors and health experts who curate personalised treatments and provide comprehensive care at every step.</li>
                        <li><strong> Powered by Ayurveda:</strong> Since the very beginning, we have always considered Ayurveda to be an important part of how we operate and to offer patients the most reliable and effective treatments.</li>
                        <li><strong> Backed by Modern Medicine:</strong> : Committed to providing high-quality, high-performance treatments developed with modern medicine and dedicated, ground-breaking research.</li>
                        <li><strong> Supported by Good Nutrition:</strong> We understand the importance of good nutrition in leading a healthy life, and so we guide each patient through the complex world of health and nutrition through personalised diet plans and enable them to lead a healthy lifestyle.</li>
                    </ul>
                </div>
                <Image className="imgResponsive" src="/assets/images/Power-of-04.gif" alt="" height={600} width={600} />
            </div>
        </div>
    </section>
}