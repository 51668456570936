import { useGlobalContext } from "@/libs/context/GlobalContext";
import Image from "next/image"
import { useEffect, useState } from "react";
type props = {
    noOfTile: Object | any,
    scrollContainerRef: any,
    tileClass: string,
    tilesToView: number,
    extraWidth?: number,
    arrowsNotReq?:Boolean
}
export function Scroller({ scrollContainerRef, noOfTile, tileClass, tilesToView, extraWidth,arrowsNotReq=false }: props) {
    const { props: { isMobileView } } = useGlobalContext();
    const [selected, setSelected] = useState(0);
    const [activeDot, setActiveDot] = useState(0);
    const _isArrowsReq = isMobileView || arrowsNotReq
    const noOfTileScroll = isMobileView ? 1 : tilesToView;
    const tilesToScroll = Math.ceil(noOfTile.length / noOfTileScroll);
    const handleSelect = (index: number) => {
      setSelected(index);
      scrollContainerRef.current!.scrollLeft = index * noOfTileScroll * getItemWidth();
    };
  
    const getItemWidth = () => {
      const item: any = document.querySelector(`.${tileClass}`);
      return item ? item.offsetWidth + (extraWidth ? extraWidth : 0) : 0;
    };
  
    const handlePrevious = () => {
      if (selected > 0) {
        setSelected(selected - 1);
        scrollContainerRef.current!.scrollLeft = (selected - 1) * noOfTileScroll * getItemWidth();
      }
    };
  
    const handleNext = () => {
      if (selected < tilesToScroll - 1) {
        setSelected(selected + 1);
        scrollContainerRef.current!.scrollLeft = (selected + 1) * noOfTileScroll * getItemWidth();
      }
    };
  
    const handleScroll = () => {
        const scrollPosition = scrollContainerRef.current!.scrollLeft;
        const itemWidth = getItemWidth();
        let activeIndex = Math.ceil(scrollPosition / (itemWidth * noOfTileScroll));
        activeIndex = Math.min(activeIndex, Math.max(0, tilesToScroll - 1));
        setActiveDot(activeIndex);
      };
      
  
    useEffect(() => {
      scrollContainerRef.current!.scrollLeft = selected * noOfTileScroll * getItemWidth();
    }, [selected]);
  
    useEffect(() => {
      scrollContainerRef.current!.addEventListener('scroll', handleScroll);
      return () => {
        scrollContainerRef.current!?.removeEventListener('scroll', handleScroll);
      };
    }, []);
    if(tilesToView >= noOfTile.length) return null ;
     return (<>
        {!_isArrowsReq && <>
            <div className="corowselarw corowselleft" onClick={() => handlePrevious()}>
                <Image src="/assets/images/doubleArwleft.svg" alt="leftArrow" height={20} width={12} />
            </div>
            <div className="corowselarw corowselright" onClick={() => handleNext()}>
                <Image src="/assets/images/doubleArwright.svg" alt="RightArrow" height={20} width={12} />
            </div>
        </>
        }
        <div className="corouselNavs">
            {noOfTile.slice(0, tilesToScroll).map((_: any, idx: number) => (
                <span
                    className={`dot ${activeDot === idx ? "active" : ""}`}
                    key={idx}
                    onClick={() => handleSelect(idx)}
                ></span>
            ))}
        </div>
    </>)
}