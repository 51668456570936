import { IHomeData } from "@/interfaces";
import { BannerSection, DiscoverOurTreatment, Faqs, FeaturedIn, HowItWorks, LabtestBanner, NirvasaApproach, OnNews, OurMedicalBoard, OurSuccessRate, PatientStories, WhatWeTreat } from ".";
type props = {
    HomeData: IHomeData
}
export function HomePage({ HomeData }: props) {
    return (
        <>
            <BannerSection bannerData={HomeData.homeData}/>
            <WhatWeTreat categories={HomeData.categories} />
            <OnNews />
            <HowItWorks howItWorksData={HomeData.homeData.how_it_works}/>
            <DiscoverOurTreatment categories={HomeData.categories}/>
            <NirvasaApproach />
            <OurSuccessRate />
            <OurMedicalBoard doctorsList={HomeData.doctorsList} />
            {/* <LabtestBanner /> */}
            <Faqs faqs={HomeData.homeData.FAQ}/>
            <PatientStories  patientStories={HomeData.patientStories}/>
            <FeaturedIn />
        </>
    );
}

