import styles from './OurSuccessRate.module.css'
export function OurSuccessRate() {
    return <section className={styles.ourSuccessrate}>
        <div className="container">
            <h2 className={`${styles.sectionHeading} sectionHeading mb15 textfff`}>Why Choose us</h2>
            <p className="textcenter font18 fontsm14 fontThin textfff">We leave no stone unturned when it comes to
                improving lives of our customers <br/> by providing comforting, convenient and seamless care</p>
            <div className="content">
                <ul className={styles.successRate}>
                    <li>
                        <p className="text552814  font45 fontsm30 fontBold ">98%</p>
                        <p className="font16 fontsm14 fontThin lineHeight24 resplineheight18 text552814">Men regained their sexual
                            confidence in the bedroom</p>
                    </li>
                    <li>
                        <p className="text416941  font45 fontsm30 fontBold ">11.8kg</p>
                        <p className="font16 fontsm14 fontThin lineHeight24 resplineheight18 text416941">Average weight loss within 3
                            months</p>
                    </li>
                    <li>
                        <p className="text285064  font45 fontsm30 fontBold ">8/10</p>
                        <p className="font16 fontsm14 fontThin lineHeight24 resplineheight18 text285064">Users noticed a reduction in hair
                            fall within 15 days</p>
                    </li>
                    <li>
                        <p className="text145A3C  font45 fontsm30 fontBold ">75%</p>
                        <p className="font16 fontsm14 fontThin lineHeight24 resplineheight18 text145A3C">Users came across 2.1% average
                            HbA1C drop in their sugar levels</p>
                    </li>
                    <li>
                        <p className="text5F4670  font45 fontsm30 fontBold ">95%</p>
                        <p className="font16 fontsm14 fontThin lineHeight24 resplineheight18 text5F4670">Users are able to manage their
                            stress and anxiety</p>
                    </li>

                </ul>
            </div>
        </div>
    </section>
}