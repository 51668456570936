export * from './HomePage';
export * from './BannerSection';
export * from './DiscoverOurTreatment';
export * from './Faqs';
export * from './FeaturedIn';
export * from './HowItWorks';
export * from './LabtestBanner';
export * from './NirvasaApproach';
export * from './OnNews';
export * from './OurMedicalBoard';
export * from './OurSuccessRate';
export * from './PatientStories';
export * from './TermAndCopyright';
export * from './WhatWeTreat';