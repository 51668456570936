import { ICategories, ISubCategories } from '@/interfaces';
import styles from './DiscoverOurTreatment.module.css';
import Image from 'next/image';
import { NavLink } from '@/components/shared/ui';
import { useGlobalContext } from '@/libs/context/GlobalContext';
type props = {
    categories: ICategories[]
}
export function DiscoverOurTreatment({ categories }: props) {
    const { props: { siteUrl } } = useGlobalContext()
    return <section className={styles.discoverourtreatment}>
        <div className="container">
            <h2 className={`${styles.sectionHeading} sectionHeading mb15 textfff`}>Discover Our Treatment</h2>
            <div className={styles.ourTreatmentsWrap}>
                {categories.map((f: ICategories, idx: number) =>
                    <div className={styles.treatmentType} key={idx}>
                        <div className={styles.treatmentDetails}>
                            <NavLink href={`${siteUrl}/${f.slug}`} >
                            <h3 className={`text506E61 font22 fontsm13 fontBold ${styles.treatHeading}`}>{f.name}
                                <Image src="/assets/images/greenrigthpoint.svg" alt="greenrigthpoint" height={20} width={12} />
                            </h3>
                            </NavLink>
                            <ul className={`${styles.subcategory} mb15`}>
                                {f.subcategory.map((m: ISubCategories, idx: number) =>
                                    <li className="lineHeight3 normallineheight5" key={idx}>
                                        <NavLink className="font18 fontsm12 text666" href={`${siteUrl}/${f.slug}/${m.slug}`}>
                                            <Image className='mr5px' src="/assets/images/angle-double-right.svg" alt="angle-double-right" height={15} width={16} />
                                            {m.name}</NavLink>
                                    </li>
                                )}
                            </ul>

                        </div>
                        <figure>
                            <Image className="imgResponsive" src={f.thumbnail} height={300} width={295} alt={f.name} />
                        </figure>
                    </div>)}
            </div>
        </div>
    </section>
}